import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { toHttpParams } from 'app/services/utils.service';
import { SKIP_CACHE_BUSTER_INTERCEPTOR } from 'app/blocks/interceptor/cache-buster.interceptor';
import { AppSettings } from 'app/services/app-settings.types';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AppSettingsService {
  constructor(private http: HttpClient) {}

  get(forceFetch = false): Promise<AppSettings> {
    if (forceFetch) {
      return firstValueFrom(this.http.get('/api/public/app-settings'));
    }
    const params = toHttpParams({ [SKIP_CACHE_BUSTER_INTERCEPTOR]: true });
    return firstValueFrom(this.http.get('/api/public/app-settings', { params }));
  }

  save(data: AppSettings): Promise<void> {
    return firstValueFrom(this.http.put('/api/app-settings', data)).then(() => undefined);
  }
}
