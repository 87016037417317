import { z } from 'zod';

export type JudgedCategory = {
  categoryId: number;
  categoryName: string;
  included: boolean;
};

export enum FilterType {
  INCLUDED = 'INCLUDED',
  EXCLUDED = 'EXCLUDED',
}

export type CreateJudgeRegistrationDto = {
  judgeId?: number;
  clubId: number;
  judgedCategories: JudgedCategory[];
  foodPreferences?: string;
  email: string;
  phone?: string;
  judgeClass: string;
  judgeName: string;
  blockedDays: Date[];
  selectedSports: string[];
  blockedMagApparatuses: string[];
  aggTestCompleted: boolean;
  aggRules2023TestCompleted: boolean;
  aggRules2025TestCompleted: boolean;
  dayFilterType: FilterType;
};

export type JudgeRegistration = CreateJudgeRegistrationDto & {
  registrationId: number;
  memberId: number;
  judgeName: string;
  eventId: number;
  clubName: string;
  clubShortName: string;
  registrationDate: Date;
};

export const createJudgeRegistrationDtoSchema = z.object({
  judgeId: z.number().optional(),
  clubId: z.number(),
  judgedCategories: z.array(
    z.object({
      categoryId: z.number(),
      categoryName: z.string(),
      included: z.boolean(),
    })
  ),
  foodPreferences: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string().optional().nullable(),
  judgeClass: z.string(),
  judgeName: z.string(),
  blockedDays: z.array(z.date()),
  selectedSports: z.array(z.string()),
  blockedMagApparatuses: z.array(z.string()).optional().nullable(),
  aggTestCompleted: z.boolean().optional().nullable(),
  aggRules2023TestCompleted: z.boolean().optional().nullable(),
  aggRules2025TestCompleted: z.boolean().optional().nullable(),
  dayFilterType: z.nativeEnum(FilterType),
});

export const judgeRegistrationSchema = createJudgeRegistrationDtoSchema.omit({ blockedDays: true }).extend({
  blockedDays: z.array(z.union([z.date(), z.string().pipe(z.coerce.date())])),
  registrationId: z.number(),
  memberId: z.number(),
  eventId: z.number(),
  clubName: z.string(),
  clubShortName: z.string().optional().nullable(),
  registrationDate: z.union([z.date(), z.string().pipe(z.coerce.date())]),
});
